@import './assets/huurstunt/scss/utilities/common';

.newsletter-wrapper {
  background-color: $white;
  border: 1px solid $dark-green;
  border-top: 7px solid $dark-green;
  width: 100%;
  padding: 2rem;
  min-height: 250px;
  overflow: visible;

  header {
    p:first-of-type {
      font-size: 1.6em;
      text-transform: none;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 0.5rem;
      color: $font-primary-color;
      line-height: 1.3;
    }
    p {
      margin-bottom: 0;
    }
    margin-bottom: .5rem;
  }

  .newsletter-search__email-disclaimer {
    color: $font-secondary-color;
    margin: 1rem 0 0;
    font-size: .75rem;
    line-height: 1.6;

    a {
      text-decoration: underline;
      color: $dark-green;
    }
  }

  .form-fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
    margin: 1rem 0 0;

    .form-field--style-1 {
      justify-content: flex-end;
      padding: 0;
      flex: 1;

      label {
        color: $font-primary-color;
        padding: 0;
      }

      .input-with-icon {
        min-width: 180px;

        #newsletter_interval.form-control {
          font-size: 1rem;
        }
      }
    }

    .form-field--email {
      flex: 2;
    }

    button {
      background-color: $dark-green;
      color: $white;
      font-weight: 600;
      margin: 0;
      min-width: 100%;
      &:hover {
        background-color: #007175;
      }
    }

    label {
      color: $font-primary-color;
    }

    input,
    select {
      box-shadow: none;
    }

    select {
      padding: 5px;
    }
  }
}

.newsletter-wrapper.boxed-widget {
  box-shadow: 2px 2px 20px 4px rgba(97, 97, 97, 0.13) !important;
}

@include media-breakpoint-down(md) {
  .newsletter-wrapper {
    padding: 1.5rem;

    .form-field--email {
      min-width: 100%;
    }

    .form-fields {
      button {
        margin-top: .5rem;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  #newsletter-flash {
    margin-bottom: 25px;
  }
}
